export namespace Constants {

    export const Ensayo={
        estado:{
            analisis: 'analisis',
            normal: 'normal',
            precaucion: 'precaucion',
            alerta:'alerta',
            porRepetir:'por_repetir',
            cargado:'cargado'
        }
    }
    export const Muestra={
        estado:{
            pending_approval:'pending_approval',
            reported:'reported',
            in_analysis:'in_analysis'
        }
    }
    export const FechasCierre=[
        {name:'Sin definir', value:'sinDefinir' },
        {name:'Mediados de mes', value:'mediadosMes'},
        {name:'Fin de mes', value:'finMes'},
    ]
}
